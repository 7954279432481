import { faDumpsterFire } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import { Button, Result } from "antd";
import React from "react";

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    state: Readonly<State> = { hasError: false };

    constructor(props: Props) {
        super(props);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error) {
        Sentry.captureException(error);
        this.setState({ hasError: true });
    }

    render() {
        return (
            <>
                <div className="w-full flex items-center justify-center bg-white">
                    <Result
                        title="Error"
                        subTitle="Sorry, something went wrong in the app."
                        icon={<FontAwesomeIcon icon={faDumpsterFire} size="6x" color="#ff9e00" />}
                        extra={
                            <>
                                <Button onClick={() => history.back()} type="default">
                                    Go Back
                                </Button>
                                <Button onClick={() => location.reload()} type="primary">
                                    Reload Page
                                </Button>
                            </>
                        }
                    />
                </div>
            </>
        );
    }
}

export default ErrorBoundary;
