import { faCaretCircleDown } from "@fortawesome/pro-regular-svg-icons";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import React, { useState } from "react";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import Tooltip from "../UI/Tooltip";

const ProjectSelect: React.FC = () => {
    const [changeProjectAnchor, setChangeProjectAnchor] = useState<null | HTMLElement>(null);
    const [changeTeamAnchor, setChangeTeamAnchor] = useState<null | HTMLElement>(null);
    const [expandNotMemberOfJobsites, setExpandNotMemberOfJobsites] = useState(false);
    const context = React.useContext(GlobalStoreContext);
    return (
        <>
            {context.currentTeamsProjects && (
                <>
                    <Tooltip title="Change Jobsites">
                        <IconButton
                            size="small"
                            id="change-jobsite-button"
                            aria-controls={changeProjectAnchor ? "change-jobsite-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={changeProjectAnchor ? "true" : undefined}
                            onClick={(e) => setChangeProjectAnchor(e.currentTarget)}
                        >
                            <FontAwesomeIcon icon={faCaretCircleDown} color="#ff9e00" />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="change-jobsite-menu"
                        anchorEl={changeProjectAnchor}
                        open={changeProjectAnchor ? true : false}
                        onClose={() => setChangeProjectAnchor(null)}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        {context.currentTeamsProjects?.member_of
                            .sort((a, b) => a.project_name.localeCompare(b.project_name))
                            .map((project) => (
                                <MenuItem
                                    sx={{ minWidth: "200px" }}
                                    key={project.id}
                                    onClick={() => {
                                        window.location.href = `/projects/${project.unique_token}`;
                                    }}
                                >
                                    <span className="truncate">{project.project_name}</span>
                                </MenuItem>
                            ))}

                        {context.currentTeamsProjects?.not_member_of.length > 0 && (
                            <>
                                <Divider />

                                <MenuItem
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "space-between",
                                    }}
                                    onClick={() => setExpandNotMemberOfJobsites(!expandNotMemberOfJobsites)}
                                >
                                    More <FontAwesomeIcon icon={faArrowDown} />
                                </MenuItem>

                                <Collapse in={expandNotMemberOfJobsites} timeout="auto" unmountOnExit>
                                    {context.currentTeamsProjects?.not_member_of
                                        .sort((a, b) => a.project_name.localeCompare(b.project_name))
                                        .map((project) => (
                                            <MenuItem
                                                sx={{ marginLeft: "1rem" }}
                                                key={project.id}
                                                onClick={() => {
                                                    window.location.href = `/projects/${project.unique_token}`;
                                                }}
                                            >
                                                <span className="truncate">{project.project_name}</span>
                                            </MenuItem>
                                        ))}
                                </Collapse>
                            </>
                        )}
                    </Menu>{" "}
                </>
            )}
        </>
    );
};

export default ProjectSelect;
