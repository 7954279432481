import { faExcavator, faSend } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect } from "react";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import Helper from "../../Helper";
import Modal from "../UI/Modal";

const RequestToJoinProject: React.FC = () => {
    const globalStoreContext = useContext(GlobalStoreContext);
    const [modalOpen, setModalOpen] = React.useState(false);

    const [email, setEmail] = React.useState<boolean>(false);
    const [mobile, setMobile] = React.useState<boolean>(false);
    const [teamId, setTeamId] = React.useState<number>(-1);
    const [showOptions, setShowOptions] = React.useState<boolean>(false);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                document.getElementById("requestJoinSubmitBtn")?.click();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        Helper.plotFetch(
            `${Helper.baseUrlWithPort()}/projects/${globalStoreContext.currentProjectInfo.unique_token}/request_join`,
            {
                method: "POST",
                body: JSON.stringify({
                    name: e.target.name.value,
                    email: e.target.email.value,
                    phone: e.target.phone.value,
                    team: e.target.team.value,
                    team_id: e.target.team_id.value > 0 ? e.target.team_id.value : null,
                    message: e.target.message.value,
                }),
            }
        ).then((response) => {
            if (response.error) {
                // Do nothing if an error comes back for now
            } else {
                setModalOpen(false);
            }
        });
    };

    // Combination of the teams on the project and the GC team for the project because it's not included in the currentProject teams for some reason
    const teams = globalStoreContext.teamsForProject(globalStoreContext.currentProjectInfo.unique_token).map((team) => {
        return {
            label: team.team_name,
            value: team.id,
        };
    });

    const checkIfTeamExists = (teamName: string) => {
        // Don't even check unless they've typed 3 characters
        if (teamName.length < 3) {
            return false;
        } else {
            return teams.find((team) => team.label.toLowerCase().includes(teamName.toLowerCase()));
        }
    };

    return (
        <>
            <button
                type="button"
                onClick={() => {
                    setModalOpen(true);
                }}
                className={"plot-button primary-button"}
            >
                <div className="flex flex-row items-center">
                    <FontAwesomeIcon icon={faExcavator} className="mr-2" />
                    {globalStoreContext.currentProjectInfo.owning_team_id ? "Join" : "Claim"} Jobsite
                </div>
            </button>
            <Modal
                title={`Request to ${globalStoreContext.currentProjectInfo.owning_team_id ? "Join" : "Claim"} ${
                    globalStoreContext.currentProjectInfo.project_name
                }`}
                onClose={() => {
                    setModalOpen(false);
                }}
                onCancel={() => {
                    setModalOpen(false);
                }}
                open={modalOpen}
                footer={false}
            >
                <div>
                    Fill out the form below to
                    {globalStoreContext.currentProjectInfo.owning_team_id ? " request access to the " : " claim the "}
                    {globalStoreContext.currentProjectInfo.project_name} jobsite.
                </div>
                <br />

                {globalStoreContext.currentProjectInfo.owning_team_id ? (
                    <div>You can be invited via email or phone.</div>
                ) : (
                    <div>Contact information to be used for your user and team.</div>
                )}
                <form onSubmit={submitForm}>
                    <TextField fullWidth id="name" label="Full Name" variant="standard" required />
                    <TextField
                        fullWidth
                        id="email"
                        label="Email Address"
                        variant="standard"
                        required={!email && !mobile ? true : false}
                        onChange={(e) => {
                            setEmail(e.target.value.length > 0 ? true : false);
                        }}
                    />
                    <TextField
                        fullWidth
                        id="phone"
                        label="Phone"
                        variant="standard"
                        required={!email && !mobile ? true : false}
                        onChange={(e) => {
                            setMobile(e.target.value.length > 0 ? true : false);
                        }}
                    />
                    {/* <TextField fullWidth id="team" label="Team" variant="standard" required /> */}
                    <Autocomplete
                        openOnFocus={false}
                        open={showOptions}
                        id="team"
                        freeSolo
                        options={teams}
                        onChange={(e, value) => {
                            if (value) {
                                // value.value is the team id
                                setTeamId(value.value);
                                setShowOptions(false);
                            } else {
                                setTeamId(-1);
                                setShowOptions(false);
                            }
                        }}
                        onInputChange={(e, value) => {
                            if (checkIfTeamExists(value)) {
                                setShowOptions(true);
                            } else {
                                setShowOptions(false);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={
                                    globalStoreContext.currentProjectInfo.owning_team_id ? "Contractor" : "Company Name"
                                }
                                variant="standard"
                                required
                            />
                        )}
                    />
                    <input hidden name="team_id" value={teamId} />
                    <TextField fullWidth id="message" label="Message" variant="standard" multiline rows={4} />
                    <div className="flex flex-row items-center justify-end gap-4 mt-4">
                        <button
                            className="plot-button alternate-button"
                            onClick={(e) => {
                                e.preventDefault();
                                setModalOpen(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button id="requestJoinSubmitBtn" type="submit" className="plot-button primary-button">
                            <FontAwesomeIcon icon={faSend} className="mr-2" />
                            Send Request
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default RequestToJoinProject;
