import React from "react";

interface Props {
    project: any;
    className?: string;
}

export const IntegrationName: React.FC<Props> = ({ project, className }) => {
    const integrationMapping: { [key: string]: string } = {
        procore: "Procore",
        procore_sandbox: "Procore",
        autodesk_construction: "Autodesk Build",
    };

    const integrationName = integrationMapping[project.integration_source];

    return <span className={className}>{integrationName}</span>;
};

export default IntegrationName;
