import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Helper from "../../Helper";

interface Props {
    project: any;
    disabled?: boolean;
    className?: string;
    onClick?(): void;
    activated?: boolean;
}

export const IntegrationIcon: React.FC<Props> = (props) => {
    const [iconSource, setIconSource] = React.useState<string>("");

    useEffect(() => {
        if (!props.project) return;

        if (props.project.integration_source == "procore" || props.project.integration_source == "procore_sandbox") {
            if (props.disabled || !props.activated) {
                setIconSource("/img/png/Procore_Icon_Disabled.png");
            } else {
                setIconSource("/img/png/Procore_Icon.png");
            }
        } else if (props.project.integration_source == "autodesk_construction") {
            if (props.disabled || !props.activated) {
                setIconSource("/img/png/Autodesk_Build_Icon_Disabled.png");
            } else {
                setIconSource("/img/png/Autodesk_Build_Icon.png");
            }
        }
    }, [props.project, props.disabled, props.activated]);

    return iconSource && iconSource.length > 0 ? (
        <img
            src={iconSource}
            className={Helper.classNames("inline w-[20px]", props.className)}
            onClick={props.onClick}
        />
    ) : (
        <div className="invisible" onClick={() => !props.disabled && props.onClick && props.onClick()}>
            <FontAwesomeIcon icon={faQuestion} className="w-[20px]" />
        </div>
    );
};

export default IntegrationIcon;
