import { initialize } from "@procore/procore-iframe-helpers";
import React, { useEffect } from "react";

const ProcoreIntegrationSuccess: React.FC<any> = () => {
    const context = initialize();

    useEffect(() => {
        context.authentication.notifySuccess({ success: true });
    }, []);

    return <div>Procore Connect Success</div>;
};

export default ProcoreIntegrationSuccess;
